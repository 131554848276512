import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import firebase from "firebase";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';
import Exposure from '@material-ui/icons/Exposure';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid } from '@material-ui/core';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import LocalAudioLevelIndicator from './DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';

const firebaseConfig = {
  apiKey: "AIzaSyA5oPInGwPeOPEUO8qYFnYc2CwG4F0soGU",
  authDomain: "teleconsultacetrus.firebaseapp.com",
  databaseURL: "https://teleconsultacetrus.firebaseio.com",
  projectId: "teleconsultacetrus",
  storageBucket: "teleconsultacetrus.appspot.com",
  messagingSenderId: "340629313983",
  appId: "1:340629313983:web:509040a77ba67aa8bbe90a",
};
firebase.initializeApp(firebaseConfig);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    joinButton: {
      margin: '1em',
    },
  })
);

export default function MenuBar() {
  const database = firebase.database();
  const classes = useStyles();
  const { URLRoomName } = useParams();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect, isAcquiringLocalTracks } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || 'CetrusLabWeb');
  const [roomName, setRoomName] = useState<string>('CetrusLab');
  const [exposureCompensation, setExposureCompensation] = useState<number>(0);
  const [minExposureCompensation, setMinExposureCompensation] = useState<number>(0);
  const [maxExposureCompensation, setMaxExposureCompensation] = useState<number>(0);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }
  }, [URLRoomName]);

  useEffect(() => {
    database.ref("exposureCompensation").once("value", (snapshot) => {
      setExposureCompensation(snapshot.val())
    });
    
    database.ref("minExposure").once("value", (snapshot) => {
      setMinExposureCompensation(snapshot.val())
    });

    database.ref("maxExposure").once("value", (snapshot) => {
      setMaxExposureCompensation(snapshot.val())
    });
  },[database])

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleExposureCompensationChange = (event: any, newValue: any) => {
    setExposureCompensation(newValue);
    database.ref().update({ exposureCompensation: newValue });
  };  

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    getToken(name, roomName).then(token => connect(token));
  };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {(roomState === 'disconnected') && (
          <form className={classes.form} onSubmit={handleSubmit}>
            {window.location.search.includes('customIdentity=true') || !user?.displayName ? (
              <TextField
                id="menu-name"
                label="Name"
                className={classes.textField}
                value={name}
                onChange={handleNameChange}
                margin="dense"
                disabled={true}
              />
            ) : (
              <Typography className={classes.displayName} variant="body1">
                {user.displayName}
              </Typography>
            )}
            <TextField
              id="menu-room"
              label="Room"
              className={classes.textField}
              value={roomName}
              onChange={handleRoomNameChange}
              margin="dense"
              disabled={true}
            />
            <Button
              className={classes.joinButton}
              type="submit"
              color="primary"
              variant="contained"
              disabled={isAcquiringLocalTracks || isConnecting || !name || !roomName || isFetching}
            >
              Join Room
            </Button>
            {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
          </form>
        )}

        {(roomState !== 'disconnected') && (
          <Grid container alignItems="center" alignContent="center">
            <Grid item lg={2} xs={4}>
              <h3>{roomName}</h3> 
            </Grid>
            <Grid item lg={4} xs={6}>
              <Grid container spacing={2} alignItems="center" alignContent="center">
                <Grid item>
                  <Exposure />
                </Grid>
                <Grid item xs>
                  <Slider
                    min={minExposureCompensation}
                    max={maxExposureCompensation}
                    disabled={minExposureCompensation === maxExposureCompensation}
                    step={1}
                    value={exposureCompensation}
                    onChange={handleExposureCompensationChange}
                    aria-labelledby="exposure-compensation-slider"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <div className={classes.rightButtonContainer}>
          <FlipCameraButton />
          <LocalAudioLevelIndicator />
          <ToggleFullscreenButton />
          <Menu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
